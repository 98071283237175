<template>
  <section class="page uk-section">
    <div class="uk-container">
      <component
        :is="componentName"
        :serviceName="SERVICE_ACRONYM"
        :removeBodyPaddingLeft="removeBodyPaddingLeft"
      ></component>

    </div>
  </section>
</template>

<script>
import People from '@/components/site/about/People.vue'
import Publications from '@/components/site/publications/Publications.vue'
import { SERVICE_ACRONYM } from '../../../../config'

export default {
  name: "PersonCardsPage",
  components: {
    People,
    Publications,
  },
  props: {
    collectionName: {
      type: String,
      default: '',
      validate: value => value.length > 0
    },
    componentName: {
      type: String,
      default: '',
      validate: value => value.length > 0
    },
    removeBodyPaddingLeft: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {
      SERVICE_ACRONYM
    }
  }
}
</script>

<style scoped>

</style>
