<template>
    <div class="container px1300">
      <div class="section">
        <div class="columns is-multiline">
          <div class="column is-2">
            <img :src="'/logo_blue.png'">
          </div>
          <div class="column is-6">
            <p><span class="title">{{ userName }}</span></p>
            <p><span class="labeling">Email: </span><span>{{ email }}</span></p>
            <p><span class="labeling">Applications :</span><span> {{ appCount }} </span></p>
          </div>
          <div class="column is-4">
            <ul>
              <li>
                <router-link :to="{name: 'UpdateAccountEmail'}">
Update account email
</router-link>
              </li>
              <li>
                <router-link :to="{name: 'UpdateAccountPassphrase'}">
Update account passphrase
</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
      </div>
</div>
</template>

<script>
  import * as actions from '../../store/graph/operations'

  export default {
    name: "Account",
    computed: {
      userName() {
        return this.user.username
      },
      email() {
        return this.user.email
      },
      appCount() {
        let apps = Object.values(this.$store.state.applications).filter(app => app.ownerUsername === this.user.username)
        return apps.length
      }
    },
    mounted() {
      this.$store.dispatch(actions.LIST_USER_APPLICATIONS)
    },
  }
</script>

<style scoped>
.px1300 {
  width: 1300px;
}
.column {
  border: aqua;
  border-style: groove;
}
.labeling {
  color: #828282;
  font-size: 1rem;
  font-weight: 700;
}
</style>
