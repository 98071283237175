<template>
  <div class="center logo">
    <div v-if="serviceName === 'CHCAA'">
      <CHCAALogo></CHCAALogo>
    </div>
    <div
        v-else-if="serviceName === 'IHPC'"
        class="IHPC center logo"
    >
      <UCloudLogo></UCloudLogo>
    </div>
    <div
        v-else-if="serviceName === 'CEDHAR'"
        class="CEDHAR center logo"
    >
      <CEDHARLogo color="white"></CEDHARLogo>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import CHCAALogo from "@/components/logos/CHCAALogo";
import UCloudLogo from "@/components/logos/UCloudLogo";
import CEDHARLogo from "@/components/logos/CEDHARLogo";

export default {
  name: "CenterLogo",
  components: {
    CHCAALogo,
    CEDHARLogo,
    UCloudLogo,
  },
  setup() {
    const store = useStore()
    const serviceName = store.state.serviceName
    return {
      serviceName
    }
  },
}
</script>

<style scoped lang="stylus">
.CHCAA.logo
  position relative

</style>
